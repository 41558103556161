<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">添加</el-button>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:image="{row}">
        <el-image
          style="width: 60px; height: 60px"
          :src="row.image"
          fit="cover"
          :preview-src-list="[row.image]"></el-image>
      </template>
      <template v-slot:client="{row}">
        <span>{{toStr({1:'需求端',2:'师傅端',3:'全部'}, row.client)}}</span>
      </template>
      <template v-slot:status="{row}">
        <span :style="{color: row.status == 1 ? '#67c23a' : '#f56c6c'}">{{row.status == 1 ? '上架' : '下架'}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" :icon="row.status == 1 ? 'el-icon-bottom' : 'el-icon-top'" @click="handleStatus(row)">{{row.status == 1 ? '下架' : '上架'}}</el-button>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'BillList',
  components: {
    VTable,
    Edit,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "client", label: "所属端口", hidden: false },
        { name: "image", label: "图片", hidden: false },
        { name: "name", label: "关联活动", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "create_time", label: "创建时间", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "200", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/flex_poster/list', { params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.getTable();
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    
    // 上架、下架
    handleStatus(row) {
      let str = row.status == 2 ? '上架' : '下架'
      this.$confirm(`请确认${str}操作？`, "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.$http.post('/admin/flex_poster/operation', { id: row.id }).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！')
                this.getTable();
              } else {
                this.$message.error(res.data)
              }
            }).finally(() => { this.getTable() })
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
  }
}
</script>