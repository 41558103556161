<template>
  <el-dialog
    append-to-body
    width="680px"
    top="3vh"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      size="small"
    >
      <!-- 编辑 -->
      <el-form-item prop='name' label='关联活动' v-if="isChange == true">
        <el-input v-model="form.data.name" :readonly="isChange"></el-input>
      </el-form-item>
      <el-form-item prop='activity_id' label='关联活动' v-else>
        <el-select v-model="form.data.activity_id" style="width:100%;">
          <el-option v-for="item in activityOpt" :label="item.name" :value="item.id" :key="item.id" ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop='image' label='活动封面' class="uploadBillimg">
        <el-upload
          class="avatar-uploader"
          name="image"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest">
          <img v-if="form.data.image" :src="form.data.image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div class="el-upload__tip imtTip" slot="tip">（建议上传尺寸为724*260px的图片）</div>
        </el-upload>
      </el-form-item>
    
      <el-form-item prop='details_image' label='活动详情图' class="uploadBill">
        <el-upload
          class="avatar-uploader"
          name="details_image"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest">
          <img v-if="form.data.details_image" :src="form.data.details_image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item prop="rule" label="活动规则">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows:10}"
          placeholder="请输入内容"
          v-model="form.data.rule"
          maxlength="500"
          show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item prop='alter_image' label='弹窗海报' class="uploadBill">
        <el-upload
          class="avatar-uploader"
          name="alter_image"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest">
          <img v-if="form.data.alter_image" :src="form.data.alter_image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div class="el-upload__tip imtTip" slot="tip">（建议上传尺寸为550*580px的图片）</div>
        </el-upload>
      </el-form-item>
      <el-form-item prop='status' label='状态'>
        <el-radio-group v-model="form.data.status">
          <el-radio :label="1">上架</el-radio>
          <el-radio :label="2">下架</el-radio>
        </el-radio-group>
      </el-form-item>
      
      
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>

    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img v-if="dialogVisible" width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'BillEdit',
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            activity_id: '',
            image: '',
            details_image: '',
            rule: '',
            alter_image: '',
            status: 1,
          },
          rules: {
            activity_id: [{ required: true, message:'必填项', trigger: 'change' }],
            image: [{ required: true, message: '必填项', trigger: 'change' }],
            details_image: [{ required: true, message: '必填项', trigger: 'change' }],
            rule: [{ required: true, message: '必填项', trigger: 'change' }],
          }
        },
        dialogVisible: false,
        dialogImageUrl: [],
        activityOpt: [],
      }
    },
    created() {
      this.getActivity();
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true;
        this.$nextTick(() => {
          common.deepClone(this.form.data, row)
        })
      },
      // 获取关联活动
      getActivity() {
        this.$http.get('/admin/flex_poster/activity', {params:{type: 1} }).then(res => {
          if(res.code == 1) {
            this.activityOpt = res.data;
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {
              id: this.form.data.id,
              activity_id: this.form.data.activity_id,
              image: this.form.data.image,
              details_image: this.form.data.details_image,
              rule: this.form.data.rule,
              alter_image: this.form.data.alter_image,
              status:this.form.data.status,
            }
            let apiUrl = !this.isChange ? '/admin/flex_poster/add' : '/admin/flex_poster/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          if(file.filename == "image") {
            this.form.data.image = res.data.all_path_url;
          } else if (file.filename == "details_image") {
            this.form.data.details_image = res.data.all_path_url;
          } else if (file.filename = "alter_image") {
            this.form.data.alter_image = res.data.all_path_url;
          }
          this.$refs.elFormDom.clearValidate();
        })
      }
    }
  }
</script>
<style scoped>
.uploadBill ::v-deep .avatar-uploader-icon {
  width: 140px !important;
  height: 147px !important;
  line-height: 147px !important;
}
.uploadBill .avatar {
  width: 140px !important;
  height: 147px !important;
  line-height: 147px !important;
}
.uploadBillimg ::v-deep .avatar-uploader-icon {
  width: 240px !important;
  height: 88px !important;
  line-height: 88px !important;
}
.uploadBillimg .avatar {
  width: 240px !important;
  height: 88px !important;
  line-height: 88px !important;
}
.imtTip {
  margin-top: -7px;
  color: #409eff;
}
</style>
