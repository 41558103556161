var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-view"},[_c('div',{staticClass:"section shadow filter__wrap"},[_c('el-button',{attrs:{"size":"mini","type":"primary","plain":"","icon":"el-icon-plus"},on:{"click":_vm.showEdit}},[_vm._v("添加")])],1),_c('VTable',{attrs:{"has-pagionation":"","title":"","addText":"添加","field":_vm.field,"loading":_vm.table.loading,"data":_vm.table.data,"page":_vm.table.params.page,"pageSize":_vm.table.params.count,"total":_vm.table.total,"hasPagionation":true,"default-expand-all":true,"showOverflowTooltip":true,"showSelect":false},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":row.image,"fit":"cover","preview-src-list":[row.image]}})]}},{key:"client",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.toStr({1:'需求端',2:'师傅端',3:'全部'}, row.client)))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{style:({color: row.status == 1 ? '#67c23a' : '#f56c6c'})},[_vm._v(_vm._s(row.status == 1 ? '上架' : '下架'))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","icon":"el-icon-edit"},on:{"click":function($event){return _vm.showEdit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text","icon":row.status == 1 ? 'el-icon-bottom' : 'el-icon-top'},on:{"click":function($event){return _vm.handleStatus(row)}}},[_vm._v(_vm._s(row.status == 1 ? '下架' : '上架'))])]}}])}),_c('edit',{ref:"edit",on:{"refresh":_vm.getTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }